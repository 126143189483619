import axios from 'axios';

const AxiosInstance = axios.create();

AxiosInstance.interceptors.request.use(config => {
  const language = localStorage.getItem('language');
  if (language) {
    config.headers.language = language;
  }else{
    config.headers.language = 'en';
  }
  return config;
});

export default AxiosInstance;