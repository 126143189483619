import AxiosInstance from "./../api/AxiosInstance.js";
let videoIdentifier;

// https://stackoverflow.com/a/42483509
function buildFormData(formData, data, parentKey) {
    if (data && typeof data === 'object' && !(data instanceof Date) && !(data instanceof File)) {

        if(data.type === 'video'){
            videoIdentifier = data.title;
        }
        
        Object.keys(data).forEach(key => {
             if (data[key] instanceof File) {
                 // added this here because server wasn't correctly parsing stuff such as array of files
                 // will probably only work if json file is on the root, did not investigate much though, could be wrong
                 if (Array.isArray(data))
                     buildFormData(formData, data[key], parentKey);
                 else buildFormData(formData, data[key], key);
             }
             else buildFormData(formData, data[key], parentKey ? `${parentKey}[${key}]` : key);
        });
    } else {
        const value = data == null ? '' : data;
        if(value instanceof File){
            formData.append(parentKey, value, videoIdentifier);
        }else{
            formData.append(parentKey, value);
        }
        
    }
}

function makeFormData(data) {
    const formData = new FormData();

    buildFormData(formData, data);

    return formData;
}

/* Old, my version: Didn't work so well for nested objects
const makeFormData = (values) => {
    // object-to-form-data didn't work bc it sent the values as inputs[name][0] (e.g.) for some reason
    // but there should technically be a way to do it, as when we have our form with an input name like people[0][name],
    //   it converts properly to JSON when sending to server
    // object-to-form-data maybe did something similar but the server didnt understand well, or axios didnt. possibly try
    //    it again if needed (npm details some options, but I doubt it would help)
    const formData = new FormData();
    for (let key of Object.keys(values)) {
        let value = values[key];
        if (Array.isArray(value))
           value.forEach(element => formData.append(key, element));
        formData.append(key, value)
    }
    return formData;
}

 */

class AxiosFileSender {
    static post(url, values, config) {
        return AxiosInstance.post(url, makeFormData(values), config);
    }

    static put(url, values, config) {
        return AxiosInstance.put(url, makeFormData(values), config);
    }
}

export default AxiosFileSender;