import React, {useRef, useState}  from 'react';
import ReCAPTCHA2 from "react-google-recaptcha"
import Form from "../components/forms/Form.js";
import FormInput from "../components/forms/FormInput.js";
import FormSubmit from "../components/forms/FormSubmit.js"
import useFormKey from "../components/forms/useFormKey.js";
import PrimaryText from "../components/other/PrimaryText.js";
import { validateEmail} from "../lib/Validators.js";
import styles from "./LeafletForm.module.css";
import{SendLeaflet} from './ResourceAPI.js';
import { useTranslation } from "react-i18next";

function LeafletForm(props) {
const { t } = useTranslation();
  const formKey = useFormKey();
  //const titleInputRef = useRef();
  const [recaptchaMsg, setrecaptachaMsg] = useState(false);
  
  const reRef= useRef();
  
  const  onSubmit = async (values, done) => 
  {
    const token = await reRef.current.executeAsync();
    
    values.token = token
    
    SendLeaflet(props.pid, values).then(r => {
      if(r.message === "Email sucessful")
      {
        props.submit();
      }
      else
      {
        setrecaptachaMsg(true)
        reRef.current.reset()
        done()
      }
    });
  }   

  return (
    <Form formKey={formKey} onSubmit={onSubmit}>
      <PrimaryText> {t("resources.medical_brochure_title")}</PrimaryText>
      <PrimaryText style={{ textAlign: "left", fontSize: "small" }}>
        {" "}
        {t("resources.prescription_patient_name")}
      </PrimaryText>
      <FormInput name="nomePaciente" placeholder={t("resources.prescription_patient_name")} />
      <PrimaryText style={{ textAlign: "left", fontSize: "small" }}>
        {" "} 
        {t("resources.prescription_patient_email")}
      </PrimaryText>
      <FormInput name="email" autoComplete="email" placeholder={t("resources.prescription_patient_email_placeholder")} validator={validateEmail}/>
      <PrimaryText style={{ textAlign: "left", fontSize: "small" }}>
        {" "} 
        {t("resources.prescription_patient_clinical_name")}
      </PrimaryText>
      <FormInput name="nomeClinico" placeholder={t("resources.prescription_patient_clinical_name")}/>
      <PrimaryText style={{ textAlign: "left", fontSize: "small" }}>
        {" "} 
        {t("resources.prescription_observations_placeholder")}
      </PrimaryText>
      <FormInput name="observacoes"  placeholder={t("resources.prescription_observations_placeholder")} />
        <div className={styles.centerdivresized}>
          <ReCAPTCHA2 className={styles.center}
            sitekey="6LcRGf4dAAAAANC2qL__0bJBDeYFqhz5hEi5YXut"
            ref={reRef}
            size="invisible"
          />
        </div>
      {recaptchaMsg && <p className={styles.error}>{t("resources.medical_brochure_send_error")}</p>}
      <FormSubmit className={styles.center}>
      {t("resources.medical_brochure_send")}
      </FormSubmit>
    </Form>
    
  );
}

export default LeafletForm;
