import React from "react";

import { ReactComponent as LogoBigSVG } from "../../../assets/brand/logoBig.svg";
import { ReactComponent as LogoSmallSVG } from "../../../assets/brand/logoSmall.svg";
import amgenIcon from "../../../assets/companies/amgen.png";
import azevedosIcon from "../../../assets/companies/azevedos.png";
import beneIcon from "../../../assets/companies/bene.png";
import docWallpaper from "../../../assets/companies/docWallpaper.png";
import docWallpaperv2 from "../../../assets/companies/docWallpaperv2.png";
import menariniIcon from "../../../assets/companies/menarini.png";
import novonordiskIcon from "../../../assets/companies/novonordisk.png";
import pfizerIcon from "../../../assets/companies/pfizer.png";
import tevaIcon from "../../../assets/companies/teva.png";
import vitalinoIcon from "../../../assets/companies/vitalino.png";
import coursesIcon from "../../../assets/courses.png";
import eventsIcon from "../../../assets/events.png";
import favoriteTrainingsIcon from "../../../assets/favoriteTrainings.png";
import { ReactComponent as AppleSVG } from "../../../assets/icons/apple.svg";
import { ReactComponent as ArrowLeftSVG } from "../../../assets/icons/arrowLeft.svg";
import { ReactComponent as ArrowRightSVG } from "../../../assets/icons/arrowRight.svg";
import { ReactComponent as AttentionSVG } from "../../../assets/icons/attention.svg";
import { ReactComponent as CartSVG } from "../../../assets/icons/basket.svg";
import { ReactComponent as ChatSVG } from "../../../assets/icons/chat.svg";
import { ReactComponent as DragDropSVG } from "../../../assets/icons/dragIcon.svg";
import { ReactComponent as EmailSVG } from "../../../assets/icons/email.svg";
import { ReactComponent as GoogleSVG } from "../../../assets/icons/google.svg";
import { ReactComponent as HeartSVG } from "../../../assets/icons/heart.svg";
import linkIcon from "../../../assets/icons/link.png";
import { ReactComponent as LinkedInSVG } from "../../../assets/icons/linkedin.svg";
import { ReactComponent as LoadingSVG } from "../../../assets/icons/loading.svg";
import { ReactComponent as MBWaySVG } from "../../../assets/icons/mbway.svg";
import { ReactComponent as MS365SVG } from "../../../assets/icons/ms365.svg";
import { ReactComponent as MSTeamsSVG } from "../../../assets/icons/msteams.svg";
import { ReactComponent as MBSVG } from "../../../assets/icons/multibanco.svg";
import { ReactComponent as OutlookSVG } from "../../../assets/icons/outlook.svg";
import pdfIcon from "../../../assets/icons/pdf.png";
import { ReactComponent as PersonSVG } from "../../../assets/icons/person.svg";
import { ReactComponent as PhoneSVG } from "../../../assets/icons/phone.svg";
import { ReactComponent as TwitterSVG } from "../../../assets/icons/twitter.svg";
import { ReactComponent as WebsiteSVG } from "../../../assets/icons/website.svg";
import { ReactComponent as YahooSVG } from "../../../assets/icons/yahoo.svg";
import trainingsIcon from "../../../assets/trainings.png";
import ImageFluid from "../../image/ImageFluid.js";
import pixFailedIcon from "../../../assets/shop/pixFailedIcon.png";
import pixSuccessIcon from "../../../assets/shop/pixSuccessIcon.png";

export function LinkIcon(props) {
  return <ImageFluid src={linkIcon} alt="Link" {...props} />;
}

export function PDFIcon(props) {
  return <ImageFluid src={pdfIcon} alt="PDF" {...props} />;
}

export function ArrowLeft(props) {
  return <ArrowLeftSVG {...props} />;
}

export function ArrowRight(props) {
  return <ArrowRightSVG {...props} />;
}

export function LogoBig(props) {
  return <LogoBigSVG {...props} />;
}

export function LogoSmall(props) {
  return <LogoSmallSVG {...props} />;
}

export function AttentionIcon(props) {
  return <AttentionSVG {...props} />;
}

export function MBIcon(props) {
  return <MBSVG {...props} />;
}

export function MBWayIcon(props) {
  return <MBWaySVG {...props} />;
}

export function DragDropIcon(props) {
  return <DragDropSVG {...props} />;
}

export function TwitterIcon(props) {
  return <TwitterSVG {...props} />;
}

export function LinkedInIcon(props) {
  return <LinkedInSVG {...props} />;
}

export function WebsiteIcon(props) {
  return <WebsiteSVG {...props} />;
}

export function PersonIcon(props) {
  return <PersonSVG {...props} />;
}

export function ChatIcon(props) {
  return <ChatSVG {...props} />;
}

export function LoadingIcon(props) {
  return <LoadingSVG {...props} />;
}

export function AppleIcon(props) {
  return <AppleSVG {...props} />;
}

export function GoogleIcon(props) {
  return <GoogleSVG {...props} />;
}

export function OutlookIcon(props) {
  return <OutlookSVG {...props} />;
}

export function MS365Icon(props) {
  return <MS365SVG {...props} />;
}

export function MSTeamsIcon(props) {
  return <MSTeamsSVG {...props} />;
}

export function YahooIcon(props) {
  return <YahooSVG {...props} />;
}

export function HeartIcon(props) {
  return <HeartSVG {...props} />;
}

export function CartIcon(props) {
  return <CartSVG {...props} />;
}

export function TrainingIcon(props) {
  return <ImageFluid src={trainingsIcon} alt="trainingsIcon" {...props} />;
}

export function FavoriteTrainingsIcon(props) {
  return (
    <ImageFluid
      src={favoriteTrainingsIcon}
      alt="favoriteTrainingsIcon"
      {...props}
    />
  );
}

export function EventsIcon(props) {
  return <ImageFluid src={eventsIcon} alt="eventsIcon" {...props} />;
}

export function CoursesIcon(props) {
  return <ImageFluid src={coursesIcon} alt="coursesIcon" {...props} />;
}

export function AmgenIcon(props) {
  return <ImageFluid src={amgenIcon} alt="amgenIcon" {...props} />;
}

export function AzevedosIcon(props) {
  return <ImageFluid src={azevedosIcon} alt="azevedosIcon" {...props} />;
}

export function BeneIcon(props) {
  return <ImageFluid src={beneIcon} alt="beneIcon" {...props} />;
}

export function MenariniIcon(props) {
  return <ImageFluid src={menariniIcon} alt="menariniIcon" {...props} />;
}

export function NovonordiskIcon(props) {
  return <ImageFluid src={novonordiskIcon} alt="novonordiskIcon" {...props} />;
}

export function PfizerIcon(props) {
  return <ImageFluid src={pfizerIcon} alt="pfizerIcon" {...props} />;
}
export function TevaIcon(props) {
  return <ImageFluid src={tevaIcon} alt="tevaIcon" {...props} />;
}
export function VitalinoIcon(props) {
  return <ImageFluid src={vitalinoIcon} alt="vitalinoIcon" {...props} />;
}
export function DocWallpaper(props) {
  return <ImageFluid src={docWallpaper} alt="docWallpaper" {...props} />;
}
export function DocWallpaperv2(props) {
  return <ImageFluid src={docWallpaperv2} alt="docWallpaperv2" {...props} />;
}

export function PhoneIcon(props) {
  return <PhoneSVG {...props} />;
}

export function EmailIcon(props) {
  return <EmailSVG {...props} />;
}

export function PixPaymentStatusIcon(props) {
  const pixIcon = props.status === true ? pixSuccessIcon : pixFailedIcon;

  return (
    <div className={props.className}>
      <ImageFluid src={pixIcon} />
    </div>
  );
}
