import AxiosInstance from "./AxiosInstance.js";

import React, { useContext, useEffect, useState } from "react";
import { fetchCourses } from "./CourseAPI.js";
import useAPIItem from "./useAPIItem.js";

export const fetchTexts = () => AxiosInstance.get("/api/texts").then((res) => res.data);
export const fetchFAQs = (showAll = false) => showAll ? AxiosInstance.get("/api/faqs").then((res) => res.data) : AxiosInstance.get("/api/faqs/language").then((texts) => texts.data.faqs);
export const fetchPrivacy = () => fetchTexts().then((texts) => texts.privacy);
export const fetchTerms = () => fetchTexts().then((texts) => texts.terms);

export function useSocialMedia() {
  const [socialLinks] = useAPIItem("/api/texts/social");
  return socialLinks;
}

// NurseSeries
const SpecialCoursesContext = React.createContext([]);
export const SpecialCoursesProvider = (props) => {
  const [courses, setCourses] = useState([]);
  useEffect(() => {
    fetchSpecialCourses().then(setCourses);
  }, []);

  return (
    <SpecialCoursesContext.Provider value={courses}>
      {props.children}
    </SpecialCoursesContext.Provider>
  );
};

export const useSpecialCourses = () => {
  return useContext(SpecialCoursesContext);
};

export const fetchSpecialCourses = async () => {
  const [courses, ids] = await Promise.all([
    fetchCourses(),
    fetchSpecialCourseIds(),
  ]);

  return courses.filter((c) => ids.includes(c.id)).reverse();
};
export const fetchSpecialCourseIds = () =>
  AxiosInstance.get("/api/texts/special").then((res) => res.data);

export function doContact(name, email, message) {
    return AxiosInstance
      .post(`/api/contact/`, {
        name,
        email,
        message,
      })
      .then((res) => {
        if (!res.data.success)
          throw Error(
            "Não foi possível completar a operação, por favor tente mais tarde."
          );
      });
  }

export function doContactPartner(name, email, message, company, countryCode, phone) {
  return AxiosInstance
    .post(`/api/contact-partner/`, {
      name,
      company,
      countryCode,
      phone,
      email,
      message,
    })
    .then((res) => {
      if (!res.data.success)
        throw Error(
          "Não foi possível completar a operação, por favor tente mais tarde."
        );
    });
}

export function fetchAllFaqs() {
  return AxiosInstance.get("/api/faqs?showAll=true").then((res) => res.data);
}

export function editFAQs(content, languageId, faqId) {
  return AxiosInstance.put(`/api/faqs/${faqId}`, { languageId, faqs: content }).then((res) => res.data);
}

export function addFAQs(content, languageId) {
  return AxiosInstance.post("/api/faqs", { languageId, faqs: content }).then((res) => res.data);
}

export function editPrivacy(content) {
  return AxiosInstance
    .put("/api/texts/privacy", { privacy: content })
    .then((res) => res.data);
}

export function editTerms(content) {
  return AxiosInstance
    .put("/api/texts/terms", { terms: content })
    .then((res) => res.data);
}
