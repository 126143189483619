import "./MainNavbar.css";

import { KeyboardArrowDown } from "@mui/icons-material";
import classNames from "classnames";
import React, { useMemo, useEffect, useState } from "react";
import { useTranslation } from 'react-i18next';
import { Link, useLocation } from "react-router-dom";

import { useTypeTags } from "../../api/TagsAPI.js";
import CustomScrollbar from "../../components/scroll/CustomScrollBar.js";
import useQuery from "../../hooks/useQuery.js";

import {
  CoursesIcon,
  DatabaseIcon,
  EventsIcon,
  TrainingsIcon,
  ResourcesIcon,
  SponsorsIcon,
} from "../adhara/icons/AdminIcons.js";
import {
  CalculatorIcon,
  ConverterIcon,
  GuidelineIcon,
  InfographIcon,
  TreesIcon,
  LeafletIcon,
  ExerciseIcon,
  SpecialtyPositionSimulatorCalculatorIcon,
  IFGSimulatorCalculatorIcon,
} from "../adhara/icons/ResourceIcons.js";
import { useAuth } from "../auth/AuthProvider.js";
import LinkCard from "../card/LinkCard.js";

const MainNavbar = ({ openCollapsible }) => {
  const { user } = useAuth();
  

  const validateUser = (link) => {
    if(!user){
        link = '/login'
    }
    return link;

  }

  const trainingTags = useTypeTags("training", "MainNavBar");
  const lessTrainings = useMemo(
    () => trainingTags.slice(0, 25),
    [trainingTags]
  );

  const courseTags = useTypeTags("course", "MainNavBar");
  const lessCourses = useMemo(() => courseTags.slice(0, 25), [courseTags]);

  const eventTags = useTypeTags("event", "MainNavBar");
  const lessEvents = useMemo(() => eventTags.slice(0, 25), [eventTags]);

  const queryParams = useQuery();

  const [selectedTrainingTab, setSelectedTrainingTab] = useState(
    parseInt(queryParams.tags) || 0
  );

  useEffect(() => {
    setSelectedTrainingTab(parseInt(queryParams.tags));
  }, [queryParams]);

  const { t } = useTranslation();
  const selectedLanguage = localStorage.getItem("language") 

  return (
    <nav className="main-navbar">
      {
        selectedLanguage === 'pt' &&
        <>
          <NavbarItem
            to="/events"
            title={t("header.events")}
            noArrow={lessEvents.length <= 0}
            onMouseEnter={() =>
              openCollapsible(
                <div>
                  <CustomScrollbar className="custom-scrollbar-width">
                    <nav className="tabsP">
                      <ul>
                        {lessEvents.map((tag) => (
                          <Link
                            key={tag.id}
                            className={classNames("tabP-item", {
                              selected: selectedTrainingTab === tag.id,
                            })}
                            to={`/events?audience=0&tags=${tag.id}`}
                            onClick={() => {
                              openCollapsible(null);
                            }}
                          >
                            <button>{tag.name}</button>
                          </Link>
                        ))}
                      </ul>
                    </nav>
                  </CustomScrollbar>
                </div>
              )
            }
          />
          <NavbarItem
            to="/courses"
            title={t("header.online_courses")}
            noArrow={lessCourses.length <= 0}
            onMouseEnter={() =>
              openCollapsible(
                <div>
                  <CustomScrollbar className="custom-scrollbar-width">
                    <nav className="tabsP">
                      <ul>
                        {lessCourses.map((tag) => (
                          <Link
                            key={tag.id}
                            className={classNames("tabP-item", {
                              selected: selectedTrainingTab === tag.id,
                            })}
                            to={`/courses?audience=0&tags=${tag.id}`}
                            onClick={() => {
                              openCollapsible(null);
                            }}
                          >
                            <button>{tag.name}</button>
                          </Link>
                        ))}
                      </ul>
                    </nav>
                  </CustomScrollbar>
                </div>
              )
            }
          />
        </>
      }
      <NavbarItem
        to="/trainings?audience=0"
        title={t("header.trainings")}
        noArrow={lessTrainings.length <= 0}
        onMouseEnter={() =>
          openCollapsible(
            <div>
              <CustomScrollbar className="custom-scrollbar-width">
                <nav className="tabsP">
                  <ul>
                    {lessTrainings.map((tag) => (
                      <Link
                        key={tag.id}
                        className={classNames("tabP-item", {
                          selected: selectedTrainingTab === tag.id,
                        })}
                        to={`/trainings?audience=0&tags=${tag.id}`}
                        onClick={() => {
                          openCollapsible(null);
                        }}
                      >
                        <button>{tag.name}</button>
                      </Link>
                    ))}
                  </ul>
                </nav>
              </CustomScrollbar>
            </div>
          )
        }
      />

      <NavbarItem
        to="/resources"
        title={t("header.resources")}
        onMouseEnter={() =>
          openCollapsible(
            <div className="main-navbar-content">
              <LinkCard
                title={t("resources.calculators")}
                icon={<CalculatorIcon />}
                to="/resources/calculators"
              />
              <LinkCard
                title={t("resources.decision_trees")}
                icon={<TreesIcon />}
                to="/resources/trees"
              />
              <LinkCard
                title={t("resources.converters")}
                icon={<ConverterIcon />}
                to="/resources/tables"
              />
              <LinkCard
                title={t("resources.guidelines")}
                icon={<GuidelineIcon />}
                to="/resources/guidelines"
              />
              <LinkCard
                title={t("resources.infographics")}
                icon={<InfographIcon />}
                to="/resources/infos"
              />
              <LinkCard
                title={t("resources.sheets")}
                icon={<LeafletIcon />}
                to="/resources/leaflets"
              />
              <LinkCard
                title={t("resources.exercises")}
                icon={<ExerciseIcon />}
                to="/resources/exercises"
              />
              {
                localStorage.getItem("language") === 'pt' && <>
                  <LinkCard
                    title={t("resources.calculators_position")}
                    icon={<SpecialtyPositionSimulatorCalculatorIcon />}
                    to={validateUser("https://adhara.pt/escolhas")}
                  />
                  <LinkCard
                    title={t("resources.calculators_ifg")}
                    icon={<IFGSimulatorCalculatorIcon />}
                    to={validateUser("https://adhara.pt/ifgescolhas")}
                  />            
                </>
              }

            </div>
          )
        }
      />
      {user?.isAdmin && (
        <NavbarItem
          to="/store"
          title={t("header.store")}
          onMouseEnter={() => openCollapsible(null)}
          noArrow
        />
      )}

      {user?.isAdmin && (
        <NavbarItem
          to="/admin"
          title={t("header.admin")}
          onMouseEnter={() =>
            openCollapsible(
              <div className="main-navbar-content">
                <LinkCard
                  to="/admin/events"
                  title={t("header.events")}
                  icon={<EventsIcon />}
                />
                <LinkCard
                  to="/admin/courses"
                  title={t("header.online_courses")}
                  icon={<CoursesIcon />}
                />
                <LinkCard
                  to="/admin/training"
                  title={t("header.trainings")}
                  icon={<TrainingsIcon />}
                />
                <LinkCard
                  to="/admin/resources"
                  title={t("header.resources")}
                  icon={<ResourcesIcon />}
                />
                <LinkCard
                  to="/admin/sponsors"
                  title={t("header.sponsors")}
                  icon={<SponsorsIcon />}
                />
                <LinkCard
                  to="/admin/database"
                  title={t("header.database")}
                  icon={<DatabaseIcon />}
                />
              </div>
            )
          }
        />
      )}
    </nav>
  );
};

const NavbarItem = ({ to, title, onMouseEnter, noArrow }) => {
  const pathName = useLocation().pathname;
  const urlBase = to.slice(0, to.indexOf("?"));
  let selected = pathName.startsWith(urlBase);

  return (
    <Link
      className={classNames("main-navbar-item", { selected: selected })}
      onMouseEnter={onMouseEnter}
      to={to}
    >
      {title}
      {!noArrow && <KeyboardArrowDown style={{ marginRight: "-0.25em" }} />}
    </Link>
  );
};

/*const MainNavbarButton = ({ children, to }) => (
  <ShadowCard className="main-navbar-button">
    <Link to={to}>
      {children}
      <KeyboardArrowRight />
    </Link>
  </ShadowCard>
);

const MainNavbarLink = ({ children, to }) => (
  <Link to={to} className="main-navbar-link">
    {children}
  </Link>
);*/

export default MainNavbar;
