import React from "react";
import { useTranslation } from "react-i18next";
import {Link} from "react-router-dom";

import ImageFluid from "../components/image/ImageFluid.js";
import styles from './SponsorButton.module.css';

const SponsorButton = ({ stand }) => {
    const { t } = useTranslation()
    
    return (
    <Link to={`/sponsors/stands/${stand.id}`} className={styles.button}>
        <div className={styles.left}>
            <small>{t("sponsors.sponsored_by")}</small>
            <strong>{t("sponsors.know_more")}</strong>
        </div>
        <ImageFluid src={stand.Sponsor.logo} alt="Sponsor Logo"/>
    </Link>
)}


export default SponsorButton;