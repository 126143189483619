import React, { useContext, useEffect, useState,useRef } from "react";
import { fetchCountry, fetchCountryAnonymousUser } from "../../api/LanguageAPI.js";
import { useAuth } from "../auth/AuthProvider.js";

const PaymentInfoContext = React.createContext();

const PaymentInfoProvider = ({ children }) => {
    const { user } = useAuth();
    const [paymentInfo, setPaymentInfo] = useState(null);
    const currentUserRef = useRef(null);

    useEffect(() => {
        currentUserRef.current = user;
    }, [user]);

    useEffect(() => {
        if (user) {
            fetchCountry(user.countryId).then(country_info => {
                const paymentData = {
                    currency: country_info.currency,
                    paymentMethods: country_info.paymentMethods,
                    country: {
                        id: country_info.id,
                        name: country_info.name,
                        countryCode: country_info.countryCode,
                    },
                };
                setPaymentInfo(paymentData);
            }).catch(console.error);
        } else {
            fetchCountryAnonymousUser().then(country_info => {
                if(!currentUserRef.current || currentUserRef.current.isDummy){
                                const paymentData = {
                    currency: country_info.currency,
                    paymentMethods: country_info.paymentMethods,
                    country: {
                        id: country_info.id,
                        name: country_info.name,
                        countryCode: country_info.countryCode,
                    },
                };
                setPaymentInfo(paymentData);
                }else{
                    console.log('An user was already set');
                }
            }).catch(console.error);
        }
    }, [user?.countryId]);

    useEffect(() => {
    }, [paymentInfo])
    return (
        <PaymentInfoContext.Provider value={paymentInfo}>
            {children}
        </PaymentInfoContext.Provider>
    );
};

export default PaymentInfoProvider;

export const usePaymentInfo = () => useContext(PaymentInfoContext);
