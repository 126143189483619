import AxiosInstance from "./AxiosInstance.js";
import { useEffect, useState } from "react";
import AxiosFileSender from "../lib/AxiosFileSender.js";
import useAPIList from "./useAPIList.js";


export function useCountries() {
    const [countries, setCountries] = useState([]);
  
    useEffect(() => {
      fetchAllCountries().then(setCountries).catch(console.error);
    }, []);
  
    return [countries];
}

export function fetchAllCountries() {
    return AxiosInstance.get("/api/cross-region/configs/country").then((res) => {
      return res.data;
    });
}


export function useLanguages() {
    const [languages, setLanguages] = useState([]);
  
    useEffect(() => {
      fetchAllLanguages().then(setLanguages).catch(console.error);
    }, []);
  
    return [languages];
}

export function fetchAllLanguages() {
  return AxiosInstance.get("/api/cross-region/configs/language").then((res) => {
    return res.data;
  });
}

export function fetchLanguages() {
  AxiosInstance.get("/api/cross-region/configs/language").then((res) => {
    return res.data;
  });
}

export function useCurrencies() {
  const [currencies, setCurrencies] = useState([]);
  
  useEffect(() => {
    fetchAllCurrencies().then(setCurrencies).catch(console.error);
  }, []);
  
  return [currencies];
}

export function fetchAllCurrencies() {
  return AxiosInstance.get("/api/cross-region/configs/currency").then((res) => {
    return res.data;
  });
}

export function usePaymentMethods() {
  const [paymentMehods, setPaymentMethods] = useState([]);
  
  useEffect(() => {
    fetchAllPaymentMethods().then(setPaymentMethods).catch(console.error);
  }, []);
  
  return [paymentMehods];
}

export function fetchAllPaymentMethods() {
  return AxiosInstance.get("/api/cross-region/configs/payment-methods").then((res) => {
    return res.data;
  });
}

export function useCurrenciesISO() {
  const [currencies, setCurrencies] = useState([]);
  
  useEffect(() => {
    fetchAllCurrenciesISO().then(setCurrencies).catch(console.error);
  }, []);
  
  return [currencies];
}

export function fetchAllCurrenciesISO() {
  return AxiosInstance.get("/api/cross-region/configs/currency-iso").then((res) => {
    return res.data;
  });
}

export function usePrices() {
  const [prices, setPrices] = useState([]);

  useEffect(() => {
    fetchAllPrices().then(setPrices).catch(console.error);
  }, []);

  return [prices];
}

export function fetchAllPrices() {
  return AxiosInstance.get("/api/cross-region/configs/price/tiers").then((res) => {
    return res.data;
  });
}

export function fetchCountry(id) {
  return AxiosInstance.get(`/api/cross-region/configs/country/${id}`).then((res) => res.data);
}

export function fetchCountryAnonymousUser() {
  return AxiosInstance.get(`/api/cross-region/configs/country/anonymous`).then((res) => res.data);
}

export function fetchLanguage(id) {
  return AxiosInstance.get(`/api/cross-region/configs/language/${id}`).then((res) => res.data);
}

export function fetchCurrency(id) {
  return AxiosInstance.get(`/api/cross-region/configs/currency/${id}`).then((res) => res.data);
}

export function fetchPrice(id) {
  return AxiosInstance.get(`/api/cross-region/configs/price/tiers/${id}`).then((res) => res.data);
}

export function updateCountry(id, body) {
  return AxiosFileSender.put(`/api/cross-region/configs/country/${id}`, body).then(
    (res) => res.data
  );
}

export function updateLanguage(id, body) {
  return AxiosFileSender.put(`/api/cross-region/configs/language/${id}`, body).then(
    (res) => res.data
  );
}

export function updateCurrency(id, body) {
  return AxiosFileSender.put(`/api/cross-region/configs/currency/${id}`, body).then(
    (res) => res.data
  );
}

export function updatePrice(id, body) {
  return AxiosFileSender.put(`/api/cross-region/configs/price/tiers/${id}`, body).then(
    (res) => res.data
  );
}

export function updatePaymentMethod(id, body) {
  return AxiosFileSender.put(`/api/cross-region/configs/payment-methods/${id}`, body).then(
    (res) => res.data
  );
}

export function addCurrency(body) {
  return AxiosFileSender.post("/api/cross-region/configs/currency", body).then((res) => res.data);
}

export function addCountry(body) {
  return AxiosFileSender.post("/api/cross-region/configs/country", body).then((res) => res.data);
}

export function addLanguage(body) {
  return AxiosFileSender.post("/api/cross-region/configs/language", body).then((res) => res.data);
}

export function addPrice(body) {
  return AxiosFileSender.post("/api/cross-region/configs/price/tiers", body).then((res) => res.data);
}

export function useCountry() {
  return useAPIList("/api/cross-region/configs/country");
}

export function useLanguage() {
  return useAPIList("/api/cross-region/configs/language");
}

export function usePrice() {
  return useAPIList("/api/cross-region/configs/price/tiers");
}

export function useCurrency() {
  return useAPIList("/api/cross-region/configs/currency");
}

export function useLanguagesTraining(languageId) {
  const [items, , edit, deleteItem] = useAPIList(
    `/api/trainings/${languageId}/language`
  );
  return [items, edit, deleteItem];
}

export function useLanguagesCourse(languageId) {
  const [items, , edit, deleteItem] = useAPIList(
    `/api/courses/${languageId}/language`
  );
  return [items, edit, deleteItem];
}