import React, {Suspense} from "react";
import {BrowserRouter as Router, Routes} from "react-router-dom";

import {SpecialCoursesProvider} from "../api/TextsAPI.js";
import GoogleAnalytics from "../components/analytics/GoogleAnalytics.js";
import AuthProvider from "../components/auth/AuthProvider.js";
import CookieBanner from "../components/cookies/CookieBanner.js";
import ErrorBoundary from "../components/error/ErrorBoundary.js";
import ImportFallback from "../components/error/ImportFallback.js";
import NetworkDetector from "../components/error/NetworkDetector.js";
import PaymentInfoProvider from "../components/payments/PaymentInfoProvider.js";
import ScrollToTop from "../components/scroll/ScrollToTop.js";
import CartProvider from "../components/shopping/CartProvider.js";

const AppSkeleton = (props) => (
    <React.StrictMode>
        <Router>
            <ErrorBoundary>
                <ScrollToTop/>
                <AuthProvider>
                    <PaymentInfoProvider>
                        <SpecialCoursesProvider>
                            <CartProvider>
                                <Suspense fallback={<ImportFallback/>}>
                                    <Routes>
                                        {props.children}
                                    </Routes>
                                    <CookieBanner/>
                                    <GoogleAnalytics/>
                                    <NetworkDetector/>
                                </Suspense>
                            </CartProvider>
                        </SpecialCoursesProvider>
                    </PaymentInfoProvider>
                </AuthProvider>
            </ErrorBoundary>
        </Router>
    </React.StrictMode>
);

export default AppSkeleton;