import { KeyboardArrowUp, KeyboardArrowDown } from "@mui/icons-material";
import React, { useState, useEffect, useMemo } from "react";
import { useTranslation } from "react-i18next";
import Select, { components } from "react-select";
import "./LanguageDropdown.css";
import { useLanguages } from "../../api/LanguageAPI.js";

const DropdownIndicator = (props) => {
  const { menuPlacement } = props.selectProps;

  return (
    <components.DropdownIndicator {...props}>
      {menuPlacement === "top" ? <KeyboardArrowUp /> : <KeyboardArrowDown />}
    </components.DropdownIndicator>
  );
};

const LanguageDropdown = ({ width = "170px", ...props }) => {
  const { i18n, t } = useTranslation();
  const [languages] = useLanguages();

  const [selectedLanguage, setSelectedLanguage] = useState(() => {
    return localStorage.getItem("language") || "";
  });

  const customOption = (language) => {
    try {
      const currentLang = languages.find(
        (lang) => lang.languageIso === selectedLanguage
      );

      const hasLanguageNameTranslations =
        currentLang &&
        currentLang.i18nConfigs.languageNameTranslations &&
        currentLang.i18nConfigs.languageNameTranslations[language.languageIso];

      const objectToReturn =
        (selectedLanguage !== "" || selectedLanguage !== null) &&
        hasLanguageNameTranslations
          ? currentLang.i18nConfigs.languageNameTranslations[
              language.languageIso
            ]
          : language.name;
          
      return (
        <div className="custom-option">
          <img
            src={language.image}
            alt={language.name}
            className="flag-image"
          />
          <span>{objectToReturn}</span>
        </div>
      );
    } catch (e) {
      console.log(e);
    }
  };

  const handleLanguageChange = (selectedOption) => {
    const newLang = selectedOption.languageIso;
    setSelectedLanguage(newLang);
    localStorage.setItem("language", newLang);
    i18n.changeLanguage(newLang);

    window.location.reload();

    if (props.closeModal) props.closeModal();
  };

  useEffect(() => {
    localStorage.setItem("language", selectedLanguage);
    i18n.changeLanguage(selectedLanguage);
  }, [i18n, selectedLanguage]);

  return (
    <div className="language-dropdown-container" style={{ width }}>
      <Select
        key={selectedLanguage}
        value={languages.find((lang) => lang.languageIso === selectedLanguage)}
        getOptionValue={(item) => item.languageIso}
        options={languages}
        formatOptionLabel={(language) => customOption(language)}
        components={{ IndicatorSeparator: () => null, DropdownIndicator }}
        onChange={handleLanguageChange}
        className="language-select"
        classNamePrefix="language-select"
        isSearchable={false}
        menuPlacement={props.menuPlacement}
        placeholder={t("common.select_language")}
        menuPortalTarget={document.body}
        styles={{
          control: (provided) => ({
            ...provided,
            width: width,
          }),
          menu: (provided) => ({
            ...provided,
            width: width,
          }),
          menuPortal: (provided) => ({
            ...provided,
            width: width,
            zIndex: 9999,
          }),
          option: (provided) => ({
            ...provided,
            cursor: "pointer",
          }),
        }}
      />
    </div>
  );
};

export default LanguageDropdown;
