import i18n from "i18next";
import HttpBackend from "i18next-http-backend";
import { initReactI18next } from "react-i18next";
import fallbackTranslations from "./locales/pt.json";

// Initialize i18next
// eslint-disable-next-line import/no-named-as-default-member
i18n
  .use(HttpBackend)
  .use(initReactI18next)
  .init({
    backend: {
      loadPath: "/api/cross-region/configs/language/{{lng}}/translations",
      allowMultiLoading: false,
    },
    //fallbackLng: "pt",
    debug: false,
    interpolation: {
      escapeValue: false,
    },
  })

i18n.on("failedLoading", (lng, ns, msg) => {
  console.warn(`Failed to load ${ns} for ${lng}: ${msg}`);
  i18n.addResourceBundle("pt", "translation", fallbackTranslations, true, true);
});

export default i18n;
