import React, {useState } from "react"

import { useTranslation } from "react-i18next";
import {
    fetchResourcesByFilter,
    fetchResourcesByTheme,
    fetchResourcesByQuery

} from "../api/ResourceAPI.js";
import RegularHeading from "../components/header/RegularHeading.js";
import APIFetcher from "../components/loading/APIFetcher.js";
import useHash from "../hooks/useHash.js";
import useQuery from "../hooks/useQuery.js";
import RegularLayout from "../layouts/RegularLayout.js";
import ExerciseItems from "./ExerciseItems.js"

const Exercises = () => {
    // const { resourceType } = useParams();
    const resourceType = "bodyparts"
    const { query, letter, theme } = useQuery();
    const hash = useHash(); // specialty, all, popular, favorite

    const [resources, setResources] = useState([]);
    const [usePagination, setPagination] = useState(true)
    const { t } = useTranslation()

    const fetchItems = (page) => {
        const limit = 20;
        const offset = page * limit;

        const callback = (resources) => {
            if (page === 0)
                setResources(resources)
            else setResources(prevResources => prevResources.concat(resources))

            if (resources.length < limit)
                setPagination(false);
        }

        if (query)
            return fetchResourcesByQuery(resourceType, query, limit, offset).then(callback);
        if (hash === "specialty")
            return fetchResourcesByTheme(resourceType, theme, limit, offset).then(callback)
        return fetchResourcesByFilter(resourceType, hash, letter, limit, offset).then(callback)
    }




    return (
            <RegularLayout title={t("resources.exercises")}>
            <RegularHeading>{t("resources.exercise_prescription")}</RegularHeading>
                <APIFetcher fetchItems={fetchItems} hasItems={resources.length > 0} reloadOnChange={[resourceType,hash,query,letter,theme]} enablePagination={usePagination}>
                    <ExerciseItems resources = {resources}></ExerciseItems>
                </APIFetcher> 
            </RegularLayout>
    );
}


export default Exercises;