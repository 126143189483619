import { Container } from "@mui/material";
import PropTypes from "prop-types";
import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { useInView } from "react-intersection-observer";
import styles from "./APIFetcher.module.css";
import CircularProgress from "./CircularProgress.js";
import LoadingContainer from "./LoadingContainer.js";


const APIFetcher = ({
  fetchItems,
  hasItems = true,
  emptyMessageTitle,
  emptyImage,
  enablePagination = false,
  reloadOnChange = [],
  children,
}) => {
  const [loading, setLoading] = useState(true);
  const [hasError, setHasError] = useState(false);
  const [currentPage, setCurrentPage] = useState(0);
  const [scrollEnded, setScrollEnded] = useState(false);
  const { t } = useTranslation()

  // Setting up the useInView hook
  const { ref, inView } = useInView({
    triggerOnce: false, // Ensure we can keep checking for scrolling
  });

  const onScrollBottom = () => {
    setCurrentPage((prevPage) => prevPage + 1);
    fetchItems(currentPage + 1, () => setScrollEnded(true));
  };

  // fetchItems(currPage, endScroll)
  useEffect(() => {
    setLoading(true);
    setHasError(false);
    setCurrentPage(0);
    setScrollEnded(false);
    fetchItems(0, () => {})
      .catch((err) => {
        console.log(err);
        setHasError(true);
      })
      .finally(() => setLoading(false));
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [...reloadOnChange]);

  useEffect(() => {
    if (inView) {
      onScrollBottom();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [inView]);

  const emptyStyle = {
    textAlign: "center",
    margin: "0.5em 0 2em 0",
    fontSize: "1.5em",
  };

  if (loading) return <LoadingContainer />;
  if (hasError) return <Container style={emptyStyle}>{t("errors.server_error")}</Container>;
  if (hasItems) {
    if (enablePagination && !scrollEnded) {
      return (
        <>
          {children}
          <div ref={ref}>
            <CircularProgress style={{ margin: "1em auto" }} />
          </div>
        </>
      );
    } else return children;
  }
  if (emptyImage) {
    return (
      <Container style={emptyStyle}>
        <div className={styles.emptyContainer}>
          <div className={styles.emptyImage}>{emptyImage}</div>
          <div className={styles.emptyMessageContainer}>
            <div className={styles.emptyMessageTitle}>{emptyMessageTitle}</div>
            <div className={styles.emptyMessageContent}>{t("common.empty")}</div>
          </div>
        </div>
      </Container>
    );
  } else return <Container style={emptyStyle}>{t("common.empty")}</Container>;
};

APIFetcher.propTypes = {
  fetchItems: PropTypes.func.isRequired,
  hasItems: PropTypes.bool,
  emptyMessageTitle: PropTypes.string,
  emptyImage: PropTypes.object,
  enablePagination: PropTypes.bool,
  reloadOnChange: PropTypes.array,
};

export default APIFetcher;
