import React from "react";
import { Navigate } from "react-router-dom";

import { useAuth } from "./AuthProvider.js";

const NoLoggedUser = (props) => {
  const { user } = useAuth();

  if (user) 
    return <Navigate to="/" />;

  return <>{props.children}</>;
};

export default NoLoggedUser;
