import PropTypes from "prop-types";
import { useTranslation } from "react-i18next";


const ResourceTypeToName = (props) => {
    const { t } = useTranslation()

    switch (props.resourceType) {
        case "calculators": return t("resources.calculators");
        case "guidelines": return t("resources.guidelines");
        case "infos": return t("resources.infographics");
        case "trees": return t("resources.decision_trees");
        case "tables": return t("resources.conversion_tables");
        case "leaflets": return t("resources.sheets");
        case "exercises": return t("resources.exercises");
        case "bodyparts": return t("resources.exercises");
        default: return "Oops, isto não devia estar aqui...";
    }
}

ResourceTypeToName.propTypes = {
    resourceType: PropTypes.string.isRequired,
}

export default ResourceTypeToName;