import AxiosInstance from "./AxiosInstance.js";
import {useCallback, useEffect, useState} from "react";

import AxiosFileSender from "../lib/AxiosFileSender.js";

export default function useAPIList(baseURL, itemURL = baseURL) {
    const [items, setItems] = useState([]);

    const addItem = useCallback((body) => {
        return AxiosFileSender.post(baseURL, body).then(res => {
            setItems(prevItems => prevItems.concat([res.data]))
            return res.data;
        })
    }, [baseURL]);

    const editItem = useCallback((id, body) => {
        return AxiosFileSender.put(`${itemURL}/${id}`, body).then(res => {
            setItems(prevItems => {
                if (prevItems.find(item => item.id === id))
                    return prevItems.map(item => item.id === id ? { ...item, ...res.data } : item)
                else return prevItems.concat([res.data]);
            })
            return res.data;
        })
    }, [itemURL]);

    const deleteItem = useCallback((id) => {
        return AxiosInstance.delete(`${itemURL}/${id}`).then(res => {
            setItems(prevItems => prevItems.filter(i => i.id !== id))
            return res.data;
        })
    }, [itemURL]);



    useEffect(() => {
        AxiosInstance.get(baseURL).then(res => setItems(res.data)).catch(console.error)
    }, [baseURL]);

    return [items, addItem, editItem, deleteItem, setItems];
}

export function useAPIListLanguage(baseURL, itemURL = baseURL) {
    const [items, setItems] = useState({}); // State now stores an object with language keys

    const addItem = useCallback((body, language, languageId) => {
        body.languageId = languageId
        return AxiosFileSender.post(baseURL, body).then(res => {
            setItems(prevItems => ({
                ...prevItems,
                [language]: prevItems[language] 
                    ? prevItems[language].concat([res.data]) 
                    : [res.data],
            }));
            return res.data;
        });
    }, [baseURL]);

    const editItem = useCallback((id, body, language) => {
        return AxiosFileSender.put(`${itemURL}/${id}`, body).then(res => {
            setItems(prevItems => ({
                ...prevItems,
                [language]: prevItems[language]
                    ? prevItems[language].map(item =>
                          item.id === id ? { ...item, ...res.data } : item
                      )
                    : [res.data],
            }));
            return res.data;
        });
    }, [itemURL]);

    const deleteItem = useCallback((id, language) => {
        return AxiosInstance.delete(`${itemURL}/${id}`).then(res => {
            setItems(prevItems => ({
                ...prevItems,
                [language]: prevItems[language]?.filter(i => i.id !== id) || [],
            }));
            return res.data;
        });
    }, [itemURL]);

    useEffect(() => {
      AxiosInstance
            .get(baseURL)
            .then(res => {
                setItems(res.data || {});
            })
            .catch(console.error);
    }, [baseURL]);

    return [items, addItem, editItem, deleteItem, setItems];
}