import "./FooterNav.css";

import { Container } from "@mui/material";
import React from "react";
import { useTranslation } from "react-i18next";
import { Link } from "react-router-dom";
import LanguageDropdown from "../dropdown/LanguageDropdown.js";

function FooterNav() {
  const { t } = useTranslation()
  const selectedLanguage = localStorage.getItem("language") 

  if (selectedLanguage === 'pt')
  {
    return (
      <div className="footer-nav">
        <Container>
          <footer>
            <FooterNavItem header="Eventos">
              <Link to="/events?when=future">Próximos Eventos</Link>
              <Link to="/events?when=past">Eventos Anteriores</Link>
              <Link to="/events?when=archived">Arquivo</Link>
            </FooterNavItem>
  
            <FooterNavItem header="Cursos Online">
              <Link to="/courses?when=future">Próximos Cursos</Link>
              <Link to="/courses?when=past">Cursos Anteriores</Link>
              <Link to="/courses?when=archived">Arquivo</Link>
            </FooterNavItem>
  
            <FooterNavItem header={t("header.trainings")}>
              <Link to="/trainings">{t("trainings.next_trainings")}</Link>
            </FooterNavItem>
  
            <FooterNavItem header={t("header.resources")}>
              <Link to="/resources/calculators">{t("resources.calculators")}</Link>
              <Link to="/resources/tables">{t("resources.conversion_tables")}</Link>
              <Link to="/resources/trees">{t("resources.decision_trees")}</Link>
              <Link to="/resources/guidelines">{t("resources.guidelines")}</Link>
              <Link to="/resources/infos">{t("resources.infographics")}</Link>
              <Link to="/resources/leaflets">{t("resources.sheets")}</Link>
              <Link to="/resources/exercises">{t("resources.exercises")}</Link>
            </FooterNavItem>
  
            <FooterNavItem header="Parceiros">
              <Link to="/partner-contact">Parcerias</Link>
            </FooterNavItem>
  
            <div style={{position: 'absolute', right: 100}}>
              <LanguageDropdown menuPlacement='top'/>
            </div>
  
          </footer>
        </Container>
      </div>
      // Next
    );
  }
  else
  {
    return (
      <div className="footer-nav">
        <Container>
          <footer>  
            <FooterNavItem header={t("header.trainings")}>
              <Link to="/trainings">{t("trainings.next_trainings")}</Link>
            </FooterNavItem>
  
            <FooterNavItem header={t("header.resources")}>
              <Link to="/resources/calculators">{t("resources.calculators")}</Link>
              <Link to="/resources/tables">{t("resources.conversion_tables")}</Link>
              <Link to="/resources/trees">{t("resources.decision_trees")}</Link>
              <Link to="/resources/guidelines">{t("resources.guidelines")}</Link>
              <Link to="/resources/infos">{t("resources.infographics")}</Link>
              <Link to="/resources/leaflets">{t("resources.sheets")}</Link>
              <Link to="/resources/exercises">{t("resources.exercises")}</Link>
            </FooterNavItem>
  
            <div style={{position: 'absolute', right: 100}}>
              <LanguageDropdown menuPlacement='top'/>
            </div>          
          </footer>
        </Container>
      </div>
      // Next
    );
  }


}

const FooterNavItem = (props) => (
  <div className="footer-nav-item">
    <h2>{props.header}</h2>
    {props.children}
  </div>
);

export default FooterNav;
