import "./AppLayout.css";

import PropTypes from "prop-types";
import React, { useEffect } from 'react';
import { Helmet } from "react-helmet";

const APP_TITLE = "Adhara Academy";
const APP_DESCRIPTION =
  "Adhara Academy: Formação Médica Digital - Cursos Formativos com Certificado, Webinars, Conteúdos Pedagógicos, Recursos Médicos";

const AppLayout = (props) => {
    
  useEffect(() => {
    if (props.title) {
      document.title = props.title + ` | ${APP_TITLE}`;
    }
  }, [props.title]);

  return (
    <div className="app-container">
      <Helmet key={props.title}>
        <meta charSet="utf-8" />
        <meta
          name="description"
          content={props.description || APP_DESCRIPTION}
        />
      

        {/*  Google / Search Engine Tags
            <meta itemProp="name" content={APP_TITLE}/>
            <meta itemProp="description" content={props.description || APP_DESCRIPTION}/>
            <meta itemProp="image" content={APP_IMAGE}/>

             Facebook Meta Tags
            <meta property="og:url" content={APP_URL}/>
            <meta property="og:type" content="website"/>
            <meta property="og:title" content={APP_TITLE}/>
            <meta property="og:description" content={props.description || APP_DESCRIPTION}/>
            <meta property="og:image" content={APP_IMAGE}/>

             Twitter Meta Tags
            <meta name="twitter:card" content="summary_large_image"/>
            <meta name="twitter:title" content={APP_TITLE}/>
            <meta name="twitter:description" content={props.description || APP_DESCRIPTION}/>
            <meta name="twitter:image" content={APP_IMAGE}/>*/}
      </Helmet>
      {props.children}
    </div>
  );
};

AppLayout.propTypes = {
  title: PropTypes.string.isRequired,
  description: PropTypes.string,
};

export default AppLayout;
