import React, { useContext, useEffect, useState } from "react";
import { useLocation } from "react-router-dom";

import { checkUser } from "../../api/AuthAPI.js";

const AuthContext = React.createContext(null);

const AuthProvider = (props) => {
    const location = useLocation();
    const [user, setUser] = useState(null);
    const [loading, setLoading] = useState(true);

    const refreshUser = () => {
        setLoading(true);
        checkUser().then(setUser).catch(console.error).finally(() => setLoading(false));
    };
    
    useEffect(() => {
        refreshUser();
    }, [location]);

    /*
    useEffect(() => {
        console.log('DEBUG: user', user)
    }, [user])
    */

    return (
        <AuthContext.Provider value={{ user, setUser, loading }}>
            {props.children}
        </AuthContext.Provider>
    );
}

export default AuthProvider;

export const useAuth = () => {
    return useContext(AuthContext);
};