import "./FooterContacts.css";
import {
  Facebook as FacebookIcon,
  Instagram as InstagramIcon,
  LinkedIn as LinkedInIcon,
} from "@mui/icons-material";
import { Container, IconButton } from "@mui/material";
import React from "react";
import { useTranslation } from "react-i18next";
import { Link } from "react-router-dom";
import { useSocialMedia } from "../../api/TextsAPI.js";

function FooterContacts() {
  const { facebook, instagram, linkedIn } = useSocialMedia('fomHere');
  const { t } = useTranslation()

  return (
    <div className="footer-wrapper">
      <Container style={{ padding: 0 }}>
        <footer className="app-footer">
          <div className="footer-text">
            <p>@adhara 2020</p>
            <Link to="/faq">FAQ</Link>
            <Link to="/terms">{t("footer.terms_and_conditions")}</Link>
            <Link to="/privacy">{t("footer.privacy_policy")}</Link>
            <Link to="/contact">{t("footer.contact_us")}</Link>
            <Link
              to={"https://www.livroreclamacoes.pt/Inicio/" }
              target="_blank"
            >
              {t("footer.complaints")}
            </Link>
          </div>
          <div className="footer-icons">
            <IconButton
              className="footer-icon"
              href={facebook}
              target="_blank"
              rel="noopener"
            >
              <FacebookIcon />
            </IconButton>
            <IconButton
              className="footer-icon"
              href={instagram}
              target="_blank"
              rel="noopener"
            >
              <InstagramIcon />
            </IconButton>
            <IconButton
              className="footer-icon"
              href={linkedIn}
              target="_blank"
              rel="noopener"
            >
              <LinkedInIcon />
            </IconButton>
          </div>
        </footer>
      </Container>
    </div>
  );
}

export default FooterContacts;
