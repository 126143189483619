import React from "react";
import { CookieConsent } from "react-cookie-consent";

import { useTranslation } from "react-i18next";
import RegularLink from "../link/RegularLink.js";

const CookieBanner = () => {
  const { t } = useTranslation()

  return (
    <CookieConsent
      cookieName="has-accepted-cookies"
      acceptOnScroll={true}
      sameSite={"lax"}
      buttonText={t("buttons.accept_cookies")}
      buttonStyle={{
        backgroundColor: "var(--primary)",
        color: "white",
        padding: "1em",
      }}
    >
      {t("common.cookies_info1")}
      <RegularLink to="/privacy">{t("common.cookies_info3")}</RegularLink>
      {t("common.cookies_info3")}
    </CookieConsent>
  )
};

export default CookieBanner;
