import React from "react";
import { Navigate, useLocation } from "react-router-dom";

import { useAuth } from "./AuthProvider.js";

const AuthOnly = ({ allowUnconfirmed, children }) => {
  const { user, loading } = useAuth();
  const location = useLocation();
  if (user === null && !loading)
    return (
      <Navigate
        to={{ pathname: "/login", state: { backURL: location.pathname } }}
      />
    );

  //We commented this out because it was preventing sales, we need to think a better approach
  // if (user && !user.isConfirmed && !allowUnconfirmed)
  //     return <Navigate to={{pathname: "/profile/details", state: { backURL: location.pathname}}}/>

  return <>{children}</>;
};

export default AuthOnly;
