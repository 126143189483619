import "./SimpleModal.css";
import { Close as CloseIcon } from "@mui/icons-material";
import { Modal } from "@mui/material";
import classNames from "classnames";
import PropTypes from "prop-types";
import React, { useEffect } from "react";

const sizeToClass = {
  small: "modal-sm",
  medium: "modal-md",
  large: "modal-lg",
};

const SimpleModal = ({
  open,
  onClose,
  size = "medium",
  containerClassName,
  className,
  children,
  hideCloseIcon = false,
  disableBackdropClick = false,
}) => {
  useEffect(() => {
    const closeOnEsc = (event) => {
      if (event.keyCode === 27 && !disableBackdropClick) {
        onClose();
      }
    };
    document.addEventListener("keydown", closeOnEsc);

    return () => document.removeEventListener("keydown", closeOnEsc);
  }, [onClose, disableBackdropClick]);

  return (
    <Modal
      open={open}
      onClose={disableBackdropClick ? undefined : onClose}
      BackdropProps={{
        className: "simple-modal-backdrop",
        onClick: disableBackdropClick ? (e) => e.stopPropagation() : onClose,
      }}
      className={classNames("simple-modal-container", containerClassName)}
    >
      <div
        className={classNames("simple-modal", sizeToClass[size], className)}
      >
        {!hideCloseIcon && (
          <CloseIcon className="modal-close-icon" onClick={onClose} />
        )}
        {children}
      </div>
    </Modal>
  );
};

export default SimpleModal;

SimpleModal.propTypes = {
  open: PropTypes.bool.isRequired,
  onClose: PropTypes.func.isRequired,
  size: PropTypes.oneOf(Object.keys(sizeToClass)),
  hideCloseIcon: PropTypes.bool,
  disableBackdropClick: PropTypes.bool,
};
